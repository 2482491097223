import React, { useRef, useState, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faLocationDot,
  faPhone,
  faZ,
} from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faYoutube } from "@fortawesome/free-brands-svg-icons";
import axios from "axios";
import { toast } from "react-toastify";
// import emailjs from '@emailjs/browser'
import { Link } from "react-router-dom";
import "./footer.css";
import Logo from "../../../assets/logo/Logo KACATY.png";

function Footer() {
  const [setting, setSetting] = useState([]);
  const formSDT1 = useRef();
  const [dataContact, setDataContact] = useState({
    contactName: "Không có",
    contactPhone: "0000000000",
    contactEmail: "",
    contactNotes: "Không có",
    contactAddress: "Không có",
  });
  const [err, setErr] = useState({});
  const handleInput = (event) => {
    const field = event.target.name;
    let value = event.target.value;
    const newDataInput = { ...dataContact };
    newDataInput[field] = value;
    setDataContact(newDataInput);
    const newErr = { ...err };
    delete newErr[field];
    setErr(newErr);
  };
  useEffect(() => {
    getSetting();
  }, []);
  const getSetting = () => {
    axios
      .get(`settings`)
      .then((res) => {
        setSetting(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sendContact = () => {
    axios
      .post("/create-contact", dataContact)

      .then((res) => {
        if (res.status == 200) {
          toast(res.data.message, { type: "success", autoClose: 1000 });
          // props.onHide(true);
          setDataContact({
            contactName: "Không có",
            contactPhone: "0000000000",
            contactEmail: "",
            contactNotes: "Không có",
            contactAddress: "Không có",
          });
        } else {
        }
      })
      .catch((error) => {
        setErr(error.response?.data?.messages);
        // setErrors(error.response?.data?.messages ?? "");
      });
  };
  return (
    <footer>
      <div className="main-footer">
        <div className="container">
          {/* <Form ref={formSDT} > */}
          <Row>
            <div className="row footer-top clearfix"></div>

            <div className="row footer-link-box clearfix">
              <Col md={8} sm={6}>
                <div className="row left-f-box">
                  <div className="col-sm-6">
                    <div style={{ display: "flex" }}>
                      <Link className="navbar-brand-footer" to="/">
                        <img
                          src={
                            Logo
                            // setting?.filter((e) => e.name == "logo")[0]?.payload
                          }
                          alt="GenA_logo"
                          className="logo_footerl"
                        />
                        <img
                          src={
                            Logo
                            // setting?.filter((e) => e.name == "logo")[0]?.payload
                          }
                          alt="GenA_logo"
                          className="logo_footer"
                        />
                      </Link>
                      <h2>
                        {
                          setting?.filter((e) => e.name == "siteName")[0]
                            ?.payload
                        }
                      </h2>
                    </div>

                    <ul>
                      <li>
                        <Link to="#">
                          <FontAwesomeIcon icon={faHome} />{" "}
                          {
                            setting?.filter((e) => e.name == "address")[0]
                              ?.payload
                          }
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`tel:${
                            setting?.filter((e) => e.name == "phoneNumber")[0]
                              ?.payload
                          }
`}
                        >
                          <FontAwesomeIcon icon={faPhone} />
                          &nbsp;&nbsp;{" "}
                          {
                            setting?.filter((e) => e.name == "phoneNumber")[0]
                              ?.payload
                          }
                        </Link>
                      </li>
                    </ul>
                  </div>

                  <div className="col-sm-6">
                    <h2>Liên hệ với chúng tôi</h2>
                    <ul>
                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="text"
                          placeholder="Nhập email"
                          name="contactEmail"
                          style={{
                            borderRadius: "50px",
                            height: "30px",
                            padding: "10px",
                            marginRight: "10px",
                            border: "var(--menu-btn) 1px solid",
                          }}
                          onChange={handleInput}
                          value={dataContact?.contactEmail || ""}
                        />

                        <Link
                          style={{
                            height: "30px",
                            borderRadius: "50px",
                            display: "flex",
                            alignItems: "center",
                          }}
                          className="menu_nav"
                          onClick={() => sendContact()}
                        >
                          Gửi
                        </Link>
                        {/* <div className="button_footer">
                                                    <a href="#" className="btn d-flex align-items-center">Gửi</a>
                                                </div> */}
                      </div>
                      <span className="text-err">
                        {err.contactEmail ? err.contactEmail : ""}
                      </span>
                    </ul>
                    <ul style={{ cursor: "pointer" }}>
                      <Link
                        to={
                          setting?.filter((e) => e.name == "facebookLink")[0]
                            ?.payload ?? "#"
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {" "}
                        <FontAwesomeIcon
                          icon={faFacebook}
                          className="icon-footer"
                          style={{ color: "#000" }}
                        />
                      </Link>
                      <Link
                        to={
                          setting?.filter((e) => e.name == "zaloLink")[0]
                            ?.payload ?? "#"
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {" "}
                        <FontAwesomeIcon
                          icon={faZ}
                          // icon={faInstagram}
                          className="icon-footer"
                          style={{ color: "#000" }}
                        />
                      </Link>{" "}
                      <Link
                        to={
                          setting?.filter((e) => e.name == "youtubeLink")[0]
                            ?.payload ?? "#"
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {" "}
                        <FontAwesomeIcon
                          icon={faYoutube}
                          className="icon-footer"
                          style={{ color: "#000" }}
                        />
                      </Link>
                    </ul>
                  </div>
                </div>
              </Col>

              <div className="col-md-4 col-sm-6">
                <div className="row right-f-box">
                  <h2>
                    <FontAwesomeIcon icon={faLocationDot} /> GOOGLE MAP
                  </h2>
                  {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d1389.0013946555484!2d105.7599942804707!3d10.042483535368032!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1svi!2s!4v1685586031834!5m2!1svi!2s"
                                        width="150" height="200" style={{ border: '0' }} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe> */}
                </div>
              </div>
            </div>
          </Row>
          {/* </Form> */}
        </div>
      </div>

      <div className="copyright">
        <div className="container">
          <div className="row">
            <p className="text-center">
              Design by{" "}
              <a
                href="https://katec.vn/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Katec
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
