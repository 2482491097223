import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartPlus } from "@fortawesome/free-solid-svg-icons";
import "./product.css";
import { priceFormatter } from "../../admin/other/Format";
import { useCart } from "react-use-cart";
import { toast } from "react-toastify";
function ProductItem(data) {
  const tensp = data?.item?.productName;
  const salePrice = priceFormatter.format(data?.item?.salePrice);
  const price = priceFormatter.format(data?.item?.price);
  const image =
    data?.item?.mainImage?.imageLink ??
    data?.item?.mainImage?.imageSrc ??
    data?.logo;
  const slug = data?.item?.productSlug;
  const {
    addItem,
    items,
    updateItemQuantity,
    updateCartMetadata,
    metadata,
    cartTotal,
  } = useCart();
  const addToCart = (data) => {
    toast("Đã thêm vào giỏ hàng.", { type: "success", autoClose: 500 });

    // if (!metadata.customer_name || !metadata.discount_code) {
    updateCartMetadata({
      customer_name: "",
      customer_phone: "",
      customer_email: "",
      province_id: "",
      district_id: "",
      ward_id: "",
      customer_address: "",
      contract_desc: "",
      feeshipping: 0,
      discount_code: "",
      discount_money: 0,
      discount_type: "",
      discount_value: "",
      totalMoney: cartTotal,
      error_list: [],
    });
    // }

    return addItem(data);
  };

  return (
    <div className="single-product">
      <div className="product-img">
        {/* <a href="product-detail"> */}
        <Link to={`/product-detail/${slug}`}>
          <img className="default-img" src={image} alt="#" />
          <img className="hover-img" src={image} alt="#" />
          {/* <span className="new">New</span> */}
          <span className="out-of-stock">Hot</span>
          {/* <span className="price-dec">30% Off</span> */}
        </Link>
        {/* </a> */}
        <div className="button-head" onClick={() => addToCart(data?.item)}>
          <div className="product-action">
            <a
              data-toggle="modal"
              data-target="#exampleModal"
              title="Xem chi tiết"
              href="#"
            >
              <i className=" ti-eye"></i>
              <span>Xem chi tiết</span>
            </a>
            <a title="Yêu thích">
              <i className=" ti-heart "></i>
              <span>Thêm vào sẩn phẩm yêu thích</span>
            </a>
            {/* <a title="Compare" href="#"><i className="ti-bar-chart-alt"></i><span>Add to Compare</span></a> */}
          </div>
          <div className="product-action-2">
            <a title="Thêm vào giỏ hàng">
              {" "}
              <FontAwesomeIcon icon={faCartPlus} /> Thêm vào giỏ hàng
            </a>
          </div>
        </div>
      </div>
      <div className="product-content">
        <Link to={`/product-detail/${slug}`}>
          {" "}
          <h1>{tensp}</h1>
        </Link>
        <div className="product-price">
          <span>{salePrice} ₫</span>
          <span className="old ms-3">{price} ₫</span>
        </div>
      </div>
    </div>
  );
}

export default ProductItem;
